<template>
  <v-dialog v-model="visibility" width="720px">
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <div style="border-radius: 10px; background: #fff; padding: 16px">
      <v-row no-gutters align="center">
        <h2
          style="
            color: #1b1b1b;
            font-family: 'MacPaw Fixel';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
        >
          {{ isEdit ? `Місто` : "Нове місто" }}
          <span v-if="isEdit" style="color: #144fa9">{{ city.name }}</span>
        </h2>
        <!-- <v-row no-gutters align="center" justify="end" v-if="isEdit">
          <v-btn class="deleteCityBtn">Видалити</v-btn>
        </v-row> -->
      </v-row>

      <div class="sectionLine"></div>
      <v-text-field
        dense
        outlined
        style="
          border-radius: 10px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        "
        height="48px"
        label="Назва міста"
        placeholder="Введіть назву міста.."
        v-model="city.name"
        :error-messages="nameError"
      />
      <v-text-field
        dense
        outlined
        style="
          border-radius: 10px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        "
        height="48px"
        label="Назва міста англійською"
        placeholder="Введіть назву міста.."
        v-model="city.name_en"
        :error-messages="nameENError"
      />
      <v-text-field
        dense
        outlined
        style="
          border-radius: 10px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        "
        height="48px"
        label="Назва міста польською"
        placeholder="Введіть назву міста.."
        v-model="city.name_pl"
        :error-messages="namePLError"
      />
      <v-autocomplete
        dense
        outlined
        style="
          border-radius: 10px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        "
        height="48px"
        label="Виберіть країну"
        placeholder="Виберіть країну.."
        hide-details
        :search-input.sync="searchValue"
        v-model="city.country"
        :items="Object.values(countryList)"
        :item-text="'alternative_name'"
        :item-value="'iso2code'"
        :error-messages="countryError"
      />
      <v-btn
        width="100%"
        height="48px"
        style="
          margin-top: 40px;
          color: #fafafa !important;
          font-family: 'MacPaw Fixel';
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          border-radius: 10px;
          text-transform: none;
        "
        color="#144FA9"
        @click="isEdit ? updateCity() : createCity()"
        >{{ isEdit ? "Оновити" : "Додати" }}
      </v-btn>
      <v-row no-gutters justify="center" style="margin-top: 10px">
        <cancel-btn text="Скасувати" @click="$emit('close')" />
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import modalMixin from "../../../../mixins/modalMixin";
import cityService from "../../../../requests/Admin/cityService";
import requestFormData from "../../../../requests/requestFormData";
import CancelBtn from "../../../UI/Buttons/cancelBtn.vue";
import ErrorSnackbar from "../../../UI/Snackbars/errorSnackbar.vue";
import googleMapsService from "./../../../../requests/googleMaps/googleMapsService.js";
export default {
  mixins: [modalMixin, validationMixin],
  components: {
    CancelBtn,
    ErrorSnackbar,
  },
  data: () => ({
    city: {
      name: "",
      name_en: "",
      name_pl: "",
      country: "",
    },
    showErrorSnackbar: false,
    snackbarText: "",
    searchValue: "",
    searchTimeout: null,
    countryList: [],
  }),
  props: {
    isEdit: {
      require: false,
    },
    cityForEdit: {
      require: true,
    },
    countries: {
      require: false,
    },
  },
  validations: {
    city: {
      name: {
        required,
      },
      name_en: {
        required,
      },
      name_pl: {
        required,
      },
      country: {
        required,
      },
    },
  },
  mounted() {
    if (this.isEdit) {
      this.setCity();
    }
    this.countryList = [...this.countries];
  },
  methods: {
    async createCity() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          country_iso: this.city.country,
          translations: [
            {
              lang: "ua",
              name: this.city.name,
            },
            {
              lang: "en",
              name: this.city.name_en,
            },
            {
              lang: "pl",
              name: this.city.name_pl,
            },
          ],
        };
        if (this.city.name.toLowerCase() !== "долина") {
          let location = await this.getCoordinates(this.city.name);
          data.lat = location.lat;
          data.lng = location.lng;
        } else {
          data.lat = "48.9801451";
          data.lng = "23.9675091";
        }

        let form = requestFormData.jsonToFormData(data);
        await cityService.createCity(form).then((res) => {
          if (res.status == "Success") {
            this.$emit("successCreate");
          }
        });
      }
    },
    async updateCity() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          country_iso: this.city.country,
          alternative_name: this.city.alternative_name,
          translations: [
            {
              id: this.city.translation_id_ua,
              lang: "ua",
              name: this.city.name,
            },
            {
              id: this.city.translation_id_en,
              lang: "en",
              name: this.city.name_en,
            },
            {
              id: this.city.translation_id_pl,
              lang: "pl",
              name: this.city.name_pl,
            },
          ],
        };
        if (this.city.name.toLowerCase() !== "долина") {
          let location = await this.getCoordinates(this.city.name);
          data.lat = location.lat;
          data.lng = location.lng;
        } else {
          data.lat = "48.9801451";
          data.lng = "23.9675091";
        }

        let form = requestFormData.jsonToFormData(data);
        await cityService.updateCity(this.city.id, form).then((res) => {
          if (res.status == "Success") {
            this.$emit("successCreate");
          }
        });
      }
    },
    async getCoordinates(city_name) {
      let response = await googleMapsService.getCoordinates(city_name);
      if (response.status == "ZERO_RESULTS") {
        this.snackbarText = "Такого міста не існує";
        this.showErrorSnackbar = true;
        setTimeout(() => {
          this.showErrorSnackbar = false;
        }, 3000);
      }
      return response.results[0]?.geometry.location;
    },
    async searchCountry() {
      await cityService.searchCountry(this.searchValue).then((res) => {
        if (res.status == "Success") {
          this.countryList = res.data;
        }
      });
    },
    setCity() {
      console.log(this.cityForEdit);
      this.$set(this.city, "id", this.cityForEdit.id);
      this.$set(
        this.city,
        "alternative_name",
        this.cityForEdit.alternative_name
      );
      this.$set(this.city, "country", this.cityForEdit.country_iso);
      this.$set(
        this.city,
        "translation_id_ua",
        this.cityForEdit.translations.find((city) => city.lang == "ua")?.id
      );
      this.$set(
        this.city,
        "translation_id_en",
        this.cityForEdit.translations.find((city) => city.lang == "en")?.id
      );
      this.$set(
        this.city,
        "translation_id_pl",
        this.cityForEdit.translations.find((city) => city.lang == "pl")?.id
      );
      this.$set(
        this.city,
        "name",
        this.cityForEdit.translations.find((city) => city.lang == "ua")?.name
      );
      this.$set(
        this.city,
        "name_en",
        this.cityForEdit.translations.find((city) => city.lang == "en")?.name
      );
      this.$set(
        this.city,
        "name_pl",
        this.cityForEdit.translations.find((city) => city.lang == "pl")?.name
      );
      console.log(this.city);
    },
  },
  computed: {
    nameError() {
      const errors = [];
      let field = this.$v.city.name;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    nameENError() {
      const errors = [];
      let field = this.$v.city.name_en;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    namePLError() {
      const errors = [];
      let field = this.$v.city.name_pl;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    countryError() {
      const errors = [];
      let field = this.$v.city.country;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
  },
  watch: {
    searchValue: {
      deep: true,
      handler() {
        if (
          this.searchValue !== "" &&
          this.searchValue !== undefined &&
          this.searchValue !== null
        ) {
          clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this.searchCountry();
          }, 300);
        }
      },
    },
  },
};
</script>

<style scoped>
.deleteCityBtn {
  width: 160px !important;
  height: 46px !important;
  display: grid;
  place-items: center;
  border-radius: 10px !important;
  background: rgba(223, 58, 58, 0.1) !important;
  color: #df3a3a !important;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}
</style>