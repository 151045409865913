<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center" style="margin-top: 30px">
      <v-col cols="4" class="px-0 py-0">
        <searchField
          label="Пошук міста"
          placeholder="Введіть тут.."
          @search="searchCity"
        ></searchField>
      </v-col>
      <v-row no-gutters justify="end">
        <submit-button
          style="height: 48px !important; width: 220px !important"
          @click="showCreateNewCityModal = true"
        >
          <v-icon style="margin-right: 8px">mdi-plus</v-icon> Додати
          місто</submit-button
        >
      </v-row>
    </v-row>
    <v-data-table
      style="margin-top: 32px"
      :headers="headers"
      :items="cities"
      hide-default-footer
      hide-default-header
      :items-per-page="100"
      no-data-text="Поки що ви не додали жодного міста"
      background-color="red"
      :loading="showLoader"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            !head.sortable && head.sortable !== false
              ? (sortBy = head.value)((sortDesc = !sortDesc))
              : ''
          "
          v-for="head in props.headers"
          :key="head.id"
          style="padding: 15px"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.1em;
                color: #1b1b1b;
                cursor: pointer;
              "
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <span class="tableItem">{{ item.index }}</span>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div v-if="item.is_new || item.is_edit" style="width: 200px">
          <v-text-field
            dense
            outlined
            placeholder="Введіть тут.."
            label=""
            background-color="#FFFFFF"
            color="#C0C0C0"
            style="border-radius: 5px; padding-top: 20px"
            v-model="item.name"
            :error-messages="cityError"
            @input="cityError = []"
          />
        </div>
        <span v-else class="tableItem">{{
          item.translations.find((city) => city.lang == "ua")?.name
        }}</span>
      </template>
      <template v-slot:[`item.country`]="{ item }">
        <div v-if="item.is_new || item.is_edit" style="width: 200px">
          <v-autocomplete
            dense
            outlined
            placeholder="Введіть тут.."
            label=""
            background-color="#FFFFFF"
            color="#C0C0C0"
            style="border-radius: 5px; padding-top: 20px"
            :search-input.sync="searchValue"
            :items="Object.values(countries)"
            :item-text="'name'"
            :item-value="'iso2code'"
            v-model="item.country_iso"
          />
        </div>
        <span v-else class="tableItem">{{
          item.country.translations.find((country) => country.lang == "en")
            ?.name
        }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row no-gutters align="center" justify="end">
          <div class="iconBackground">
            <div
              class="editIcon"
              v-if="!item.is_new && !item.is_edit"
              @click="showEditCityField(item)"
            />
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.quantity_in_trip`]="{ item }">
        <span class="tableItem">{{ item.quantity_in_trip }}</span>
      </template>
    </v-data-table>
    <pagination
      :page="page"
      :quantityPage="quantityPage"
      @changePage="changePage"
    />
    <add-new-city-modal
      v-if="showCreateNewCityModal"
      :visible="showCreateNewCityModal"
      :countries="countries"
      :cityForEdit="cityForEdit"
      :isEdit="isEdit"
      @successCreate="
        (showCreateNewCityModal = false), (isEdit = false), getCities()
      "
      @close="(showCreateNewCityModal = false), (isEdit = false)"
    />
  </v-col>
</template>

<script>
import SubmitButton from "../../../UI/Buttons/submitButton.vue";
import addNewCityModal from "./addNewCityModal.vue";
import searchField from "./../../../UI/Fields/searchField.vue";
import cityService from "./../../../../requests/Admin/cityService.js";
import Pagination from "../../../UI/pagination.vue";
//import requestFormData from "../../../../requests/requestFormData";
export default {
  components: { addNewCityModal, SubmitButton, searchField, Pagination },
  data: () => ({
    sortBy: "",
    sortDesc: "",
    showConfirmModal: false,
    showCreateNewCityModal: false,
    headers: [
      {
        text: "№",
        align: "center",
        value: "id",
      },
      { text: "Місто", value: "name", align: "start" },
      { text: "Країна", value: "country", align: "start" },
      {
        text: "Використано в рейсах",
        value: "usage_count",
        align: "center",
      },
      { text: "Опції", value: "action", sortable: false, align: "end" },
    ],
    cities: [],
    countries: [],
    cityForDeleteId: "",
    cityForEdit: {},
    searchValue: "",
    cityError: [],
    showLoader: true,
    isEdit: false,
    page: 1,
    quantityPage: 0,
  }),
  mounted() {
    this.getCities();
    this.getCountries();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getCities();
    },
    async getCities() {
      await cityService.getCities(this.page).then((res) => {
        if (res.status == "Success") {
          this.cities = res.data.map(
            (city, index) => (city = { index: index + 1, ...city })
          );
          this.quantityPage = res.pagination.meta.last_page;
          this.showLoader = false;
        }
      });
    },
    async searchCity(city) {
      this.showLoader = true;
      if (city !== "" && city !== null && city !== undefined) {
        await cityService.searchCities(city).then((res) => {
          if (res.status == "Success") {
            this.cities = res.data.map(
              (city, index) => (city = { index: index + 1, ...city })
            );
            setTimeout(() => {
              this.showLoader = false;
            }, 500);
          }
        });
      } else {
        this.getCities();
      }
    },
    async getCountries() {
      await cityService.getCountries(1).then((res) => {
        if (res.status == "Success") {
          this.countries = res.data;
        }
      });
    },
    // async updateAlternativeName() {
    //   this.countries.forEach(async (country) => {
    //     let data = {
    //       iso2code: country.iso2code,
    //       alternative_name: country.translations[0]?.name,
    //       region: country.region,
    //       translations: country.translations,
    //     };
    //     let form = requestFormData.jsonToFormData(data);
    //     await cityService.updateCountry(country.id, form);
    //   });
    // },
    showEditCityField(city) {
      this.cityForEdit = city;
      this.isEdit = true;
      this.showCreateNewCityModal = true;
    },
  },
};
</script>

<style scoped>
.addNewCityBtn {
  border-radius: 10px;
  background: #144fa9 !important;
  display: flex;
  width: 220px !important;
  height: 48px !important;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}
.editIcon {
  width: 22px;
  height: 22px;
  background-color: #4b5262;
  -webkit-mask: url("../../../../assets/img/iconsSvg/editIcon.svg") no-repeat
    center;
  mask: url("../../../../assets/img/iconsSvg/editIcon.svg") no-repeat center;
}
.iconBackground:hover .editIcon {
  background-color: #fafafa;
}
</style>